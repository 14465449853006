
import { store } from "@/store";
import SecurePaymentInfo from "@/components/checkout-process/SecurePaymentInfo.vue";
import { Vue, Options } from "vue-class-component";
import NewsletterForm from "@/components/misc/NewsletterForm.vue";

@Options({
  components: { SecurePaymentInfo, NewsletterForm }
})
export default class Footer extends Vue {
  name = '';

  handleToEquire(hasName = false) {
    let leadIn = `${window.location.pathname}${window.location.search || ""}`;

    let enquiryCartId = ''
    let savedDesignId = ''

    if(leadIn.includes('/cart')) {
      enquiryCartId = store.getters["checkout/cartId"];
    }

    if(leadIn.includes('/designer') && this.$route.query['saved-design']) {
      savedDesignId = this.$route.query['saved-design'] as string;
    }

    this.$router.push({ name: 'Enquiries', params: {lang: this.$route.params.lang}, query: {from: leadIn, name: hasName ? this.name : undefined, cart: enquiryCartId || undefined,  design: savedDesignId || undefined} })

    if(hasName) {
      this.name = ''
    }
  }

  get version() {
    return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`;
  }
}
