
import { mixins, Options, Vue } from "vue-class-component";
import NavBar from "@/components/navigation/NavBar.vue"; // @ is an alias to /src
import BannerMessage from "@/components/banner-messages/BannerMessage.vue";
import Footer from "@/components/navigation/Footer.vue";
import { store } from "@/store";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "./mixins/utilities";
import { SEOSettings } from "@/network/api";
import { PageSeoSettingAdminViewModel } from "@/api-client";

@Options({
  components: {
    NavBar,
    Footer,
    BannerMessage
  },
  beforeRouteUpdate(to, from) {
    this.seoSettings.url = to.fullPath;
  }
})
export default class App extends mixins(SetMetaData) {
  seoSettings: PageSeoSettingAdminViewModel = {
    url: '',
    canonicalUrl: '',
    noIndex: false,
  };

  get showNav() {
    return (this.$route.name !== 'Designer') || (this.$route.name == 'Designer' && (!this.$route.query.order && !this.$route.query.enquiry && !this.$route.query.admin && !this.$route.query.extra))
  }

  updated() {
    this.loadSEOData(window.location.pathname);
  }

  created() {
    window.onload = () => {
      const zoho = (window as any).$zoho || null;
      if(zoho) {
        store.dispatch("user/updateZohoStatus", (zoho.salesiq.status));

        zoho.salesiq.chat?.online(() => {
          store.dispatch("user/updateZohoStatus", true);
        })

        zoho.salesiq.chat?.offline(() => {
          store.dispatch("user/updateZohoStatus", false);
        })
      }
    }
  }
  
  mounted() {
    const {meta} = useMeta({
      title: '',
      description: '',
      keywords: '',
      og: {
        title: '',
        description: '',
        type: '',
        url: ''
      },
      htmlAttrs: { lang: 'en', amp: true }
    });
    
    this.$watch('seoSettings.url', () => {
      if(this.seoSettings.noIndex) {
        this.setMetaData(meta, this.seoSettings, 'noIndex')
      }
      else{
        this.setMetaData(meta, {noIndex: null}, 'noIndex')
      }
      if(this.seoSettings.canonicalUrl) {
        this.setMetaData(meta, this.seoSettings, 'canonicalUrl')
      }
      else{
        this.setMetaData(meta, {canonicalUrl: null}, 'canonicalUrl')
        this.langRouteCanonical(meta)
      }
    })

    this.$watch("$route", () => {
      this.langRouteCanonical(meta);
    });
    this.$watch("$route.params.lang", () => {
      this.langRouteCanonical(meta);
    });

    this.loadSEOData(window.location.pathname);

    // window.removeEventListener('storage', (e) => this.syncLocalStorage(e));
    // window.addEventListener('storage', (e) => this.syncLocalStorage(e)); 

    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      const inlinksScript = document.createElement("script");
      inlinksScript.defer = true;
      inlinksScript.src = "https://jscloud.net/x/37866/inlinks.js";
      document.body.appendChild(inlinksScript);
      
      this.langRouteCanonical(meta);
    })
  }
  
  syncLocalStorage(e:any):any {
    if(!["utsdb", "quota_test", "test", "siqlsdb"].includes(e.key)) {
      localStorage.setItem(e.key, e.newValue)
    }
  }

  async loadSEOData(currentPath = ''): Promise<any> {
    if(currentPath == "") {
      currentPath = "/";
    }

    await SEOSettings.pageSeoSettingsGet(currentPath).then((res) => {
      this.seoSettings = res.data.resultData as PageSeoSettingAdminViewModel;
      if(this.seoSettings.url != currentPath) {
        this.seoSettings.url = currentPath;
      }
    })
    .catch((error) => {
      this.seoSettings = {
        url: '',
        canonicalUrl: '',
        noIndex: false,
      };

      error.response.data.errors.map((e: any) => {
          console.log(e.friendlyMessage)
          return e.friendlyMessage
        }
      );             
    });
  }
  
  langRouteCanonical(meta: any) {
    if(this.$route.params.lang && this.$route.params.lang != "") {
      const canonical = window.location.href.replace(`/${this.$route.params.lang}`, "");
      this.setMetaData(meta, {canonicalUrl: canonical}, 'canonicalUrl');
    }
  }
}
